<template>
    <v-data-table v-bind="{ headers, items }" hide-default-footer disable-sort disable-filtering disable-pagination no-data-text="No review has been submitted" class="v-data-table--default tbody-tr-cursor-pointer" @click:row="(any, { item }) => showBoard(item)">
        <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
        <template #[`item.files`]="{ value }">
            <template v-for="item in value || []">
                <v-btn x-small text icon download :key="item._id" :href="item.url" @click.stop>
                    <i class="icon icon-down-md-secondary"></i>
                </v-btn>
            </template>
            <template v-if="!value || value?.length == 0">-</template>
        </template>
        <template #footer>
            <div class="board-bottom">
                <v-row v-show="false" justify="end" class="row--x-small">
                    <v-spacer />
                    <v-col cols="auto">
                        <v-btn small outlined exact color="grey-d" class="w-100px" @click="$emit('loadmore')">List</v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn small exact color="secondary" class="w-100px" @click="$router.push(`${$route.path}/write`)">Write</v-btn>
                    </v-col>
                </v-row>
                <v-row justify="end" class="row--x-small">
                    <v-spacer />
                    <v-col cols="auto">
                        <v-btn small exact color="secondary" class="w-100px" @click="$router.push(`${$route.path}/write`)">Write</v-btn>
                    </v-col>
                </v-row>
            </div>

            <pagination-component v-bind="{ count }" :value="page" @change="changePage" />
        </template>
    </v-data-table>
</template>

<script>
import PaginationComponent from "@/components/client/control/pagination-component.vue";
const headers = [
    { align: "center", text: "Title", value: "subject", cellClass: "text-align-left" },
    { width: 160, align: "center", text: "Views", value: "viewCount" },
    { width: 160, align: "center", text: "Writer", value: "writer", formatter: (value) => value?.name || "-" },
    { width: 160, align: "center", text: "Date", value: "createdAt", formatter: (value) => value?.toDate?.() || value || "-" },
    { width: 160, align: "center", text: "Download", value: "files" },
];

export default {
    components: {
        PaginationComponent,
    },

    props: {
        code: { type: String, default: null },
        summary: {
            type: Object,
            default() {
                return { totalCount: 0 };
            },
        },
        boards: { type: Array, default: null },
        notices: { type: Array, default: null },
        page: { type: Number, default: 1 },
        skip: { type: Number, default: 0 },
        limit: { type: Number, default: null },
    },
    data: () => ({
        headers,
    }),
    computed: {
        count() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        items() {
            return this.boards.map((board, index) => ({ ...board, index: this.summary.totalCount - index - (this.page - 1) * this.limit }));
        },
    },
    methods: {
        changePage(page) {
            this.$emit("page", page);
        },
        showBoard(item) {
            this.$router.push(`${this.$route.path}/${item._id}`);
        },
    },
};
</script>

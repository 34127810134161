var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', _vm._b({
    staticClass: "v-data-table--default tbody-tr-cursor-pointer",
    attrs: {
      "hide-default-footer": "",
      "disable-sort": "",
      "disable-filtering": "",
      "disable-pagination": "",
      "no-data-text": "No review has been submitted"
    },
    on: {
      "click:row": function (any, _ref) {
        var item = _ref.item;
        return _vm.showBoard(item);
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref2) {
          var value = _ref2.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: `item.files`,
      fn: function (_ref3) {
        var value = _ref3.value;
        return [_vm._l(value || [], function (item) {
          return [_c('v-btn', {
            key: item._id,
            attrs: {
              "x-small": "",
              "text": "",
              "icon": "",
              "download": "",
              "href": item.url
            },
            on: {
              "click": function ($event) {
                $event.stopPropagation();
              }
            }
          }, [_c('i', {
            staticClass: "icon icon-down-md-secondary"
          })])];
        }), !value || (value === null || value === void 0 ? void 0 : value.length) == 0 ? [_vm._v("-")] : _vm._e()];
      }
    }, {
      key: "footer",
      fn: function () {
        return [_c('div', {
          staticClass: "board-bottom"
        }, [_c('v-row', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false"
          }],
          staticClass: "row--x-small",
          attrs: {
            "justify": "end"
          }
        }, [_c('v-spacer'), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          staticClass: "w-100px",
          attrs: {
            "small": "",
            "outlined": "",
            "exact": "",
            "color": "grey-d"
          },
          on: {
            "click": function ($event) {
              return _vm.$emit('loadmore');
            }
          }
        }, [_vm._v("List")])], 1), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          staticClass: "w-100px",
          attrs: {
            "small": "",
            "exact": "",
            "color": "secondary"
          },
          on: {
            "click": function ($event) {
              return _vm.$router.push(`${_vm.$route.path}/write`);
            }
          }
        }, [_vm._v("Write")])], 1)], 1), _c('v-row', {
          staticClass: "row--x-small",
          attrs: {
            "justify": "end"
          }
        }, [_c('v-spacer'), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          staticClass: "w-100px",
          attrs: {
            "small": "",
            "exact": "",
            "color": "secondary"
          },
          on: {
            "click": function ($event) {
              return _vm.$router.push(`${_vm.$route.path}/write`);
            }
          }
        }, [_vm._v("Write")])], 1)], 1)], 1), _c('pagination-component', _vm._b({
          attrs: {
            "value": _vm.page
          },
          on: {
            "change": _vm.changePage
          }
        }, 'pagination-component', {
          count: _vm.count
        }, false))];
      },
      proxy: true
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }